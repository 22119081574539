import React from "react"
import { ImageDisplay } from "utils"
import styles from "./imageContain.module.css"

export default (props) => (
  <div 
    className={props.theme === 0 ? styles.imageContainWhite : styles.imageContainPink}
    style={{
      minHeight: props.height ? props.height + "px" : "100vh"
    }}
  >
    <div
      className={styles.index}
      // style={{ margin: props.margin }}
    >
      <div className={styles.indexImg}>
        <ImageDisplay img={props.img} />
      </div>
      {props.text && <p>{props.text}</p>}
    </div>

  </div>
)