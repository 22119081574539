import React from 'react'
import {ParallaxProvider} from 'react-scroll-parallax'
import {enumTheme} from 'config'
import {Element} from 'react-scroll'
import Layout from 'components/layout'
import Article from 'components/Article/'
import ImageContain from 'components/ImageContain/'
import HomeTitleContain from 'components/HomeTitleContain/'
import ImageParallax from 'components/ImageParallax/'
import {getPageTitle, getPageSlug} from '../slugs'

export default props => {
  const {
    data: {
      illustrationPageAccueil,
      titreWebsite,
      soustitreWebsite,
      nombreEtoiles,
      soustitrePageAccueil,
      texteBoutonAccueil,
      buttonPageAccueil,
      descriptionPremierArticle,
      lienPremierArticle,
      texteLienPremierArticle,
      photosPremierArticle,
      lienSecondArticle,
      texteLienSecondArticle,
      lienSecondArticle2,
      texteLienSecondArticle2,
      photosSecondArticle,
      descriptionSecondArticle,
      descriptionPhotoAccueil
    },
    lang,
    slugs
  } = props

  let height = 0
  if (typeof window !== `undefined`) {
    height = window.innerWidth <= 476
      ? 980
      : window.innerHeight // \o/
      if (window.URLSearchParams !== undefined) {
        let urlParams = new window.URLSearchParams(window.location.search)
      if (urlParams.has('message')) {
        window.alert(urlParams.get('message'))
      }
      }
  }

  let links = []
  switch (lang.toLowerCase()) {
    case 'fr':
      links = ['situation', 'villas_t3', 'piscine', 'tarifs']
      break
    case 'en':
      links = ['situation', 'villas_t3', 'piscine', 'tarifs']
      break
    case 'it':
      links = ['situazione', 'villas_t3', 'piscina', 'prezzi']
      break
    default:
      break
  }

  return (
    <ParallaxProvider>
      <HomeTitleContain
        background={illustrationPageAccueil[0]}
        title={titreWebsite}
        subTitle={soustitreWebsite}
        nbStars={nombreEtoiles}
        subTitleComponent={soustitrePageAccueil}
        buttonLink={getPageSlug('photos', lang)}
        buttonText={texteBoutonAccueil}
        height={height}
        links={links}
        slugs={slugs}
        lang={lang}
        baseUrl={props.location.pathname}/>
      <Layout
        slugs={slugs}
        lang={lang}
        pageTitle={getPageTitle('index', lang)}
        headerScroll={height && height > 715
        ? height
        : 716}
        footerTheme={enumTheme.WHITE}
        socialMediaIsDark={true}
        socialMediaScroll={height}
        socialMediaOffset={height + (height / 2) - 45}
        socialMediaDisplay={height !== 0
        ? ''
        : 'none'}
        baseUrl={props.location.pathname}
        currentPage={props.currentPage}>
        <Element name="article">
          <Article
            theme={enumTheme.WHITE}
            imgFull={photosPremierArticle[0]}
            img1={photosPremierArticle[1]}
            img2={photosPremierArticle[2]}
            img3={photosPremierArticle[3]}
            text={descriptionPremierArticle}
            buttonLink={getPageSlug('situation', lang)}
            buttonText={texteLienPremierArticle}
            height={height}
            baseUrl={props.location.pathname}/>
        </Element>
        <ImageParallax img={illustrationPageAccueil[1]}/>
        <Article
          theme={enumTheme.WHITE}
          imgFull={photosSecondArticle[0]}
          img1={photosSecondArticle[1]}
          img2={photosSecondArticle[2]}
          img3={photosSecondArticle[3]}
          text={descriptionSecondArticle}
          buttonLink={getPageSlug('villas_t3', lang)}
          buttonText={texteLienSecondArticle}
          buttonLink2={getPageSlug('villa_t4', lang)}
          buttonText2={texteLienSecondArticle2}
          height={height}
          baseUrl={props.location.pathname}/>
        <ImageParallax img={illustrationPageAccueil[2]}/>
        <ImageContain
          img={illustrationPageAccueil[3]}
          text={descriptionPhotoAccueil}
          theme={0}
          scroll={height}/>
      </Layout>
    </ParallaxProvider>
  )
}
