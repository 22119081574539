import React from "react"
import { Parallax } from "react-scroll-parallax"
import { ImageDisplay } from "utils"
import styles from "./imageParallax.module.css"

export default (props) => (
  <div 
    className={styles.imageParallax}
    style={{ 
      /*maxHeight: props.height ? props.height + "px" : "370px"*/
    }}
  >
    <Parallax
        offsetYMax={100}
        offsetYMin={-100}
        slowerScrollRate={true}
        tag="figure"
    >
        <ImageDisplay img={props.img} />
    </Parallax>
  </div>
);