export const enumTheme = {
  WHITE: 0,
  PINK: 1,  
}

export const langs = ['fr', 'en', 'it'];

export const notFoundMsg = {
  fr: 'La page ne peut être affichée',
  en: 'The webpage cannot be display',
  it: 'La pagina non può essere visualizzata',
}
export const urls = {
  facebook: 'http://facebook.com',
  twitter: 'http://twitter.com',
  google: 'http://google.com',
  palombagia: 'http://clients2.boureaud.com',
  marinarossa: 'http://clients.boureaud.com',
  fr: '/',
  en: '/rental-villas-south-corsica',
  it: '/',
}

export const internalUrls = {
  fr :[
    {
      url: '',
      name: 'location'
    },
    {
      url: 'situation',
      name: 'situation'
    },
    {
      url: 'villas',
      name: 'villas'
    },
    {
      url: 'piscine',
      name: 'piscine'
    },
    {
      url: 'tarifs',
      name: 'tarifs'
    },
    {
      url: 'photos',
      name: 'photos'
    },
    {
      url: 'contact',
      name: 'contact'
    },
  ],
  en: [
    {
      url: 'rental-villas-south-corsica',
      name: 'location'
    },
    {
      url: 'situation',
      name: 'situation'
    },
    {
      url: 'villas',
      name: 'villas'
    },
    {
      url: 'piscine',
      name: 'piscine'
    },
    {
      url: 'tarifs',
      name: 'tarifs'
    },
    {
      url: 'photos',
      name: 'photos'
    },
    {
      url: 'contact',
      name: 'contact'
    },
  ],
  it: [
    {
      url: '',
      name: 'locazione'
    },
    {
      url: 'situation',
      name: 'situazione'
    },
    {
      url: 'villas',
      name: 'villas'
    },
    {
      url: 'piscine',
      name: 'piscina'
    },
    {
      url: 'tarifs',
      name: 'prezzi'
    },
    {
      url: 'photos',
      name: 'foto'
    },
    {
      url: 'contact',
      name: 'contatto'
    },
  ]
};