import React from "react"
import { graphql } from "gatsby"
import Home from "templates/home"
import { getFieldLang } from "utils"

export default (props) => {
  const {
    data: {
      allDatoCmsAccueil
    },
    pageContext: {
      lang,
      slugs
    }
  } = props;

  const data = allDatoCmsAccueil && allDatoCmsAccueil.edges[0].node;

  return (
    <div>
      {data &&  
        <Home
          data={{
              ...data,
              titreWebsite: data[`titreWebsite${lang}`],
              soustitreWebsite: data[`soustitreWebsite${lang}`],
              soustitrePageAccueil: data[`soustitrePageAccueil${lang}`],
              texteBoutonAccueil: data[`texteBoutonAccueil${lang}`],
              descriptionPremierArticle: data[`descriptionPremierArticle${lang}`],
              texteLienPremierArticle: data[`texteLienPremierArticle${lang}`],
              descriptionSecondArticle: data[`descriptionSecondArticle${lang}`],
              texteLienSecondArticle: data[`texteLienSecondArticle${lang}`],
              texteLienSecondArticle2: data[`texteLienSecondArticle2${lang}`],
              descriptionPhotoAccueil: data[`descriptionPhotoAccueil${lang}`],
          }}
          location={{ pathname: '/' }}
          currentPage={ props.location.pathname }
          lang={lang}
          slugs={slugs}
        />
      }
    </div>
  )
};

export const query = graphql`
  query HomePageQuery($website: String!) {
    allDatoCmsAccueil(filter: {website: {eq: $website}}) {
      edges {
        node {
          illustrationPageAccueil {
            id
            url
            width
            height
            fluid(maxWidth: 1920, imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
          soustitrePageAccueilFr
          soustitrePageAccueilEn
          soustitrePageAccueilIt
          soustitrePageAccueilDe
          titreWebsiteFr
          soustitreWebsiteFr
          titreWebsiteEn
          soustitreWebsiteEn
          titreWebsiteIt
          soustitreWebsiteIt
          titreWebsiteDe
          soustitreWebsiteDe
          nombreEtoiles
          texteBoutonAccueilFr
          texteBoutonAccueilEn
          texteBoutonAccueilIt
          texteBoutonAccueilDe
          descriptionPremierArticleFr
          descriptionPremierArticleEn
          descriptionPremierArticleIt
          descriptionPremierArticleDe
          texteLienPremierArticleFr
          texteLienPremierArticleEn
          texteLienPremierArticleIt
          texteLienPremierArticleDe
          photosPremierArticle {
            id
            fluid(maxWidth: 500, imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
          descriptionSecondArticleFr
          descriptionSecondArticleEn
          descriptionSecondArticleIt
          descriptionSecondArticleDe
          texteLienSecondArticleFr
          texteLienSecondArticleEn
          texteLienSecondArticleIt
          texteLienSecondArticleDe
          texteLienSecondArticle2Fr
          texteLienSecondArticle2En
          texteLienSecondArticle2It
          texteLienSecondArticle2De
          photosSecondArticle {
            id
            fluid(maxWidth: 500, imgixParams: {fm: "jpg", auto: "compress"}) {
              ...GatsbyDatoCmsFluid
            }
          }
          descriptionPhotoAccueilFr
          descriptionPhotoAccueilEn
          descriptionPhotoAccueilIt
          descriptionPhotoAccueilDe
        }
      }
    }
  }
`