import React from "react"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown"
import { ButtonDisplay, ImageDisplay } from "utils"
import styles from "./article.module.css"

export default (props) => (
  <div 
    className={props.theme === 0 ? styles.articleBackWhite : styles.articleBackPink}
    style={{
      height: props.height && props.height !== 0 ? props.height + "px" : "calc(100vh - 83px)",
      paddingBottom: `${props.paddingBottom && props.paddingBottom}`
    }}
    >
    <div className={styles.leftSide}>
      <div className={styles.imgLeftContain}><ImageDisplay img={props.imgFull} /></div>
    </div>
    <div className={styles.rightSide}>
      <div className={styles.pictDesc}>
        <div className={styles.imgContain}><ImageDisplay img={props.img1} /></div>
        <div className={styles.imgContain}><ImageDisplay img={props.img2} /></div>
        <div className={styles.imgContain}><ImageDisplay img={props.img3} /></div>
      </div>
      <div
        className={props.theme === 0 ? styles.contentTextPink : styles.contentTextWhite}
      >
        <ReactMarkdown source={props.text} />
      </div>
      <div className={styles.link}>
        {props.buttonLink && <Link 
          to={props.baseUrl + props.buttonLink}
          className={props.theme === 0 ? "pinkButton" : "whiteButton"}
        >
          <ButtonDisplay text={props.buttonText} />
        </Link>}
        {props.buttonLink2 && <Link 
          to={props.baseUrl + props.buttonLink2}
          className={props.theme === 0 ? "pinkButton" : "whiteButton"}
        >
          <ButtonDisplay text={props.buttonText2} />
        </Link>}
      </div>
    </div>    
  </div>
)