import React from "react"
import {Link} from "gatsby"
import {langs} from "config"
import Img from "gatsby-image"

export const ButtonDisplay = (props) => {
  if (!props.text) 
    return '';
  const arr = props
    .text
    .split(' ');
  return arr.map((x, i) => i === arr.length - 1
    ? <strong key={i}>{x}</strong>
    : x + ' ');
}

export const getCurrentLang = (url) => {
  for (var i = langs.length - 1; i >= 0; i--) {
    if (url.indexOf(langs[i]) === 1) {
      return (url[url.indexOf(langs[i]) + 2] === '/')
        ? langs[i] + '/'
        : '/';
    }
  }
  return '/';
};

export const ImageDisplay = (props) => props.img && props.img.fluid
  ? <Img key={props.img.id} fluid={props.img.fluid} style={props.style}/>
  : <span></span>

export const LinkDisplay = ({
  links,
  currentPage,
  separator,
  slugs,
  lang,
  isHomeLinks
}) => {
  const slugArray = isHomeLinks
    ? Object
      .keys(slugs)
      .filter(pageName => ['situation', 'villas_t3', 'villa_t4', 'tarifs'].includes(pageName))
    : Object.keys(slugs);
  return slugArray.map((pageName, index) => {
    const slugInfos = slugs[pageName];
    return (
      <div
        key={index}
        className={currentPage === "/" + slugInfos[lang.toLowerCase()]
        ? "linkDisplay active"
        : "linkDisplay"}>
        <Link to={"/" + slugInfos[lang.toLowerCase()]}>{slugInfos[`title_${lang.toLowerCase()}`]}</Link>
        {separator && index !== slugArray.length - 1 && <span></span>}
      </div>
    )
  });
  // return links.map((elt, index) =>     <div       key={index}
  // className={currentPage === "/" + elt.url ? "linkDisplay active" :
  // "linkDisplay"}     >       <Link to={"/" + elt.url}>{elt.name}</Link>
  // {separator && index !== links.length - 1 && <span></span>}     </div>   )
};

export const getLinkInArray = (listLinks, needLinks) => listLinks.filter((el) => needLinks.indexOf(el.name) !== -1);

export const Storage = {
  setItem: (key, value) => {
    if (typeof sessionStorage !== `undefined`) 
      return sessionStorage.setItem(key, value);
    }
  ,
  getItem: (key) => {
    if (typeof sessionStorage !== `undefined`) 
      return sessionStorage.getItem(key);
    }
  ,
  remove: (key) => {
    if (typeof sessionStorage !== `undefined`) 
      return sessionStorage.removeItem(key);
    }
  ,
  clear: () => {
    if (typeof sessionStorage !== `undefined`) 
      return sessionStorage.removeItem();
    }
  }

export const currentLang = Storage.getItem('lang')
  ? Storage.getItem('lang')
  : 'fr';

export const changeLang = (newLang) => {
  Storage.setItem('lang', newLang);
}

export const getFieldLang = (field) => {
  return field + currentLang
    .charAt(0)
    .toUpperCase() + currentLang.slice(1);
}

export const getUrlLang = (list) => list[currentLang];

export const getNameFromUrl = (list, current) => {
  const elt = list[currentLang].filter((el) => el.url === current);
  return (elt.length > 0
    ? elt[0].name
    : '');
}

export const capitalize = (word) => word
  .charAt(0)
  .toUpperCase() + word.slice(1);
