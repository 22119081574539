import React, {Component} from 'react';
import {urls} from "config"
import {changeLang} from "utils"
import styles from "./socialMedia.module.css"
import facebook from "assets/facebook.png"
import twitter from "assets/twitter.png"
import google from "assets/google.png"
import facebookDark from "assets/facebook_black.png"
import twitterDark from "assets/twitter_black.png"
import googleDark from "assets/google_black.png"

const updateLang = (newLang) => {
  changeLang(newLang);
  window
    .location
    .reload();
  window.scrollTo(0, 0);
};

class SocialMedia extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isScroll: false
    }

    this.handleScroll = this
      .handleScroll
      .bind(this);
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }
  handleScroll() {
    if (this.props.scroll) {
      if (window.scrollY >= parseInt(this.props.scroll) && !this.state.isScroll) {
        this.setState({isScroll: true});
      } else if (window.scrollY < parseInt(this.props.scroll) && this.state.isScroll) {
        this.setState({isScroll: false});
      }
    }
  }
  render() {
    let {lang} = this.props;
    lang = lang.toLowerCase();
    const langLinks = [];
    if (lang !== 'fr') {
      langLinks.push(
        <a href="/">FR</a>
      );
    }
    if (lang !== 'en') {
      langLinks.push(
        <a href="/rental-villas-south-corsica/">EN</a>
      );
    }
    if (lang !== 'it') {
      langLinks.push(
        <a href="/affitto-corsica-del-sud/">IT</a>
      );
    }
    if (lang !== 'de') {
      langLinks.push(
        <a href="/ferienhaus-sudkorsika/">DE</a>
      );
    }
    return (
      <div
        className={!this.state.isScroll && parseInt(this.props.offsetTop) !== 0
        ? styles.socialMediaNormal
        : styles.socialMediaFix}>
        <div
          className={!this.props.isDark
          ? styles.leftMediaThemeWhite
          : styles.leftMediaThemeDark}
          style={{
          top: !this.state.isScroll && parseInt(this.props.offsetTop) !== 0 && this.props.offsetTop
        }}>
          <a href={urls.twitter}><img src={!this.props.isDark
        ? twitter
        : twitterDark} alt="tw"/></a>
          <a href={urls.facebook}><img
            src={!this.props.isDark
        ? facebook
        : facebookDark}
            alt="fb"/></a>
          <a href={urls.google}><img src={!this.props.isDark
        ? google
        : googleDark} alt="g"/></a>
        </div>
        <div
          className={!this.props.isDark
          ? styles.rightMediaThemeWhite
          : styles.rightMediaThemeDark}
          style={{
          top: !this.state.isScroll && parseInt(this.props.offsetTop) !== 0 && this.props.offsetTop
        }}>
          {langLinks[0]}
          <span></span>
          {langLinks[1]}
          <span></span>
          {langLinks[2]}
        </div>
      </div>
    );
  }
};

export const SocialMediaFooter = () => <div className={styles.footerMedia}>
  <span>|</span>
  <div className={styles.footerMediaLeft}>
    <a href={urls.twitter}><img src={twitterDark} alt="tw2"/></a>
    <a href={urls.facebook}><img src={facebookDark} alt="fb2"/></a>
    <a href={urls.google}><img src={googleDark} alt="g2"/></a>
  </div>
</div>;

export const SocialMediaHeader = ({lang}) => {
  lang = lang.toLowerCase();
  const langLinks = [];
  if (lang !== 'fr') {
    langLinks.push(
      <a href="/">FR</a>
    );
  }
  if (lang !== 'en') {
    langLinks.push(
      <a href="/rental-villas-south-corsica/">EN</a>
    );
  }
  if (lang !== 'it') {
    langLinks.push(
      <a href="/affitto-corsica-del-sud/">IT</a>
    );
  }
  if (lang !== 'de') {
    langLinks.push(
      <a href="/ferienhaus-sudkorsika/">DE</a>
    );
  }
  return (
    <div className={styles.headerMedia}>
      <div className={styles.headerMediaRight}>
      {langLinks[0]}
          <span></span>
          {langLinks[1]}
          <span></span>
          {langLinks[2]}
      </div>
    </div>
  )
};

export default SocialMedia;