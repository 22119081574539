import React from "react"
import { Link } from "gatsby"
import { Link as LinkScroll } from 'react-scroll'
import { internalUrls } from "config"
import { ButtonDisplay, LinkDisplay, getLinkInArray, getUrlLang } from "utils"
import { ParallaxBanner } from 'react-scroll-parallax';
import downArrow from "./assets/down-arrow.svg";
import styles from "./homeTitleContain.module.css"

const StarsDisplay = function(props){
  let stars = "";
  for (var i = props.nbStars - 1; i >= 0; i--)
    stars = stars + " ★";
  return (
    <span>{stars}</span>
  );
}

export default (props) => (
  <ParallaxBanner
    className={styles.homeTitleContain}
    layers={[
        {
            image: props.background && props.background.fluid.src,
            amount: 0.3,
            slowerScrollRate: true,
        },
    ]}
    style={{
        height: props.height && props.height !== 0 ? props.height + "px" : "100vh",
    }}
  >
    <div className={styles.homeContainer}>
      <div className={styles.titleTop}>
        <h1>{props.title}</h1>
        <h3>{props.subTitle}</h3>
        <h5><StarsDisplay nbStars = {props.nbStars} /></h5>
      </div>
      <div className={styles.titleCenter}>
        <h3>{props.subTitleComponent}</h3>
        <div className={styles.linkContain}>
          <LinkDisplay
            lang={props.lang}
            slugs={props.slugs}
            isHomeLinks={true}
            links={getLinkInArray(getUrlLang(internalUrls), props.links)}
            baseUrl={props.baseUrl}
            separator
          />
        </div>
        <div className={styles.photoButton}>
          <Link to={props.baseUrl + props.buttonLink} className="whiteButton"><ButtonDisplay text={props.buttonText} /></Link>
        </div>
        <div className={styles.scrollButton}>
          <LinkScroll to="article" spy={true} smooth={true}>
            <img src={downArrow} alt="arrow" />
          </LinkScroll>
        </div>
      </div>
    </div>
  </ParallaxBanner>
)