import React from 'react'
import Header from 'components/Header/'
import Footer from 'components/Footer/'
import SocialMedia from 'components/SocialMedia/'
import Helmet from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'
import {capitalize, getNameFromUrl} from 'utils'
import {internalUrls} from 'config'

const getTitle = (props, data) => {
  console.log(props.lang);
  if (props.lang === 'Fr') {
    return props.currentPage !== undefined && props.currentPage !== '/'
    ? "Locations Palombaggia Villa Rossa" + ` - ` + props.pageTitle
        : "Locations Palombaggia Villa Rossa";
  }
  return props.currentPage !== undefined && props.currentPage !== '/'
  ? data.site.siteMetadata.title[0] + ` ` + data.site.siteMetadata.title[1] + ` - ` + props.pageTitle
      : data.site.siteMetadata.title[0] + ` ` + data.site.siteMetadata.title[1];
}

export default props => <StaticQuery
  query={graphql ` query LayoutQuery { site { siteMetadata { title description keywords } } } `}
  render={data => <div id="outer-container">
  <Helmet
    title={getTitle(props, data)}
    meta={[
    {
      name: 'description',
      content: data.site.siteMetadata.description
    }, {
      name: 'keywords',
      content: data.site.siteMetadata.keywords
    }, {
      name: 'google-site-verification',
      content: 'mkJAQxZ348qF5AHUJV_vl6H0mVtiJQj8ob03t6_JgO0'
    }
  ]}/>
  <Header
    title={data.site.siteMetadata.title[0]}
    subTitle={data.site.siteMetadata.title[1]}
    scroll={props.headerScroll}
    slugs={props.slugs}
    lang={props.lang}
    baseUrl={props.baseUrl}
    currentPage={props.currentPage}/>
  <div id="page-wrap">
    {props.children}
    {typeof window !== undefined && props.socialMediaDisplay !== 'none' && <SocialMedia
      offsetTop={props.socialMediaOffset}
      scroll={props.socialMediaScroll}
      lang={props.lang}
      isDark={props.socialMediaIsDark}/>}
    <Footer
      theme={props.footerTheme}
      currentSite={data.site.siteMetadata.title[1]}
      lang={props.lang}/>
  </div>
</div>}/>
